<template>
  <el-drawer :model-value="openHelpModal" direction="rtl" size="25%"> </el-drawer>
</template>

<script>
import { mapGetters } from "vuex";
export default {
  computed: {
    ...mapGetters("ui", ["openHelpModal"]),
  },
  data() {
    return {
      openHelpDrawer: false,
    };
  },
};
</script>
