<template>
  <NavToolsProvider>
    <div class="flex h-screen overflow-hidden">
      <NavSidebar :menu-width="menuWidth" v-if="isLargeScreen" @update:sidebar-open="handleSidebarToggle" class="fixed z-40 h-screen transition-all duration-300" :class="sidebarOpen ? 'w-64' : 'w-20'" />
      <div :class="[contentClass, isLargeScreen ? '' : '!pl-0']" class="relative flex grow flex-col overflow-y-scroll bg-arco-dbg bg-navbg bg-[length:420px_370px] bg-left-top bg-no-repeat">
        <NavHeader />

        <div class="flex grow flex-col overflow-x-hidden px-4">
          <NavBreadcrumbs />
          <router-view v-slot="{ Component, route }">
            <transition name="slide-right" mode="out-in">
              <div :key="route.name">
                <component :is="Component" />
              </div>
            </transition>
          </router-view>
        </div>
        <BaseLocked class="absolute inset-0 z-30" />
      </div>
    </div>
  </NavToolsProvider>
</template>

<script setup>
import NavToolsProvider from "@/components/Nav/NavToolsProvider.vue";
import { ref, onMounted, onUnmounted, computed, watch } from "vue";
import BaseLocked from "@/components/Base/BaseLocked.vue";
import NavSidebar from "@/components/Nav/NavSidebar.vue";
import NavHeader from "@/components/Nav/NavHeader.vue";
import NavBreadcrumbs from "@/components/Nav/NavBreadcrumbs.vue";
import NavHelpDrawer from "@/components/Nav/NavHelpDrawer.vue";
import { useStructureStore } from "@/Stores/structure";
import { useStore } from "vuex";
const vuexStore = useStore();
const store = useStructureStore();
const sidebarOpen = ref(false);

const handleSidebarToggle = (isOpen) => {
  sidebarOpen.value = isOpen;
};
let windowWidth = ref(0);
let menuWidth = ref("80px");
const contentClass = computed(() => ({
  "pl-64": sidebarOpen.value,
  "pl-20": !sidebarOpen.value,
  "transition-all": true,
  "duration-300": true,
}));
const isLargeScreen = computed(() => windowWidth.value > 640);

onMounted(() => {
  vuexStore.dispatch("tenant/getTenantData");
  window.addEventListener("resize", handleResize);
  handleResize();
  store.getStructure();
  vuexStore.dispatch("structure/getStructure");
});

onUnmounted(() => {
  window.removeEventListener("resize", handleResize);
});

function handleResize() {
  windowWidth.value = window.innerWidth;
}
</script>

<style scoped>
.nav-width {
  transition: all 0.3s;
  transition-timing-function: cubic-bezier(0.075, 0.82, 0.165, 1);
}

.slide-left-enter-active,
.slide-left-leave-active,
.slide-right-enter-active,
.slide-right-leave-active {
  transition: all 0.2s;
}

.slide-left-enter-from {
  opacity: 0;
  transform: translate(30px, 0);
}

.slide-left-leave-to {
  opacity: 0;
  transform: translate(-30px, 0);
}

.slide-right-enter-from {
  opacity: 0;
  transform: translate(-50px, 0);
}

.slide-right-leave-to {
  opacity: 0;
  transform: translate(50px, 0);
}
.transition-padding,
.nav-sidebar-transition {
  transition: transform 0.3s ease;
}
</style>
