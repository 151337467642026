<template>
  <div class="flex h-fit w-full sm:w-auto">
    <div class="h-full w-full bg-arco-dark-card">
      <nav class="border-b border-gray-700 bg-arco-dark-card sm:hidden">
        <div class="mx-auto flex max-w-screen-xl flex-wrap items-center justify-between p-4 transition-all">
          <img :src="VUE_APP_LOGO" class="h-8" />
          <button
            @click="toggleMenu()"
            type="button"
            class="inline-flex h-10 w-10 items-center justify-center rounded-lg p-2 text-sm text-gray-500 hover:bg-gray-100 focus:outline-none focus:ring-2 focus:ring-gray-200 dark:text-gray-400 dark:hover:bg-gray-700 dark:focus:ring-gray-600 md:hidden"
            aria-controls="mobilemenu"
            aria-expanded="false"
          >
            <span class="sr-only">Open Arco Menu</span>
            <svg class="h-5 w-5" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 17 14">
              <path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M1 1h15M1 7h15M1 13h15" />
            </svg>
          </button>

          <Transition name="slide">
            <div v-if="isMenuVisible" class="w-full md:block md:w-auto" id="mobilemenu">
              <div class="mt-5 w-full border-t border-gray-700" v-if="activeMenu && activeMenu.length > 0">
                <div v-for="item in activeMenu" @click="changeRoute(item.routeName, item.locked)" :key="item.routeName" class="my-2">
                  <div
                    class="flex w-full cursor-pointer items-center justify-between rounded-md px-3 py-2 text-gray-200 hover:bg-white/10"
                    :class="{
                      'bg-white/20 ': $route.name === item.routeName || $route.name.startsWith(item.active),
                    }"
                  >
                    <div v-if="item.icon" style="width: 20px">
                      <component :size="20" :is="item.icon" class="text-gray-200" />
                    </div>
                    <div v-else-if="item.iconClass" class="flex items-center justify-center">
                      <el-icon :class="item.iconClass" size="20" />
                    </div>
                    <div v-if="!item.locked" class="ml-5 w-full grow text-sm" style="width: 120px">
                      {{ item.name }}
                    </div>

                    <i v-if="item.children" class="pi pi-fw pi-angle-down" :class="$route.name === item.routeName ? 'layout-toggler' : null" />
                  </div>

                  <transition name="slide-fade">
                    <div v-if="item.children && $route.name.startsWith(item.active)" class="rounded-b-lg p-2">
                      <div
                        v-for="child in item.children"
                        :key="child.name"
                        v-show="!child.hidden"
                        class="cursor-pointer rounded-md py-2 text-sm text-gray-200 hover:bg-white/10"
                        @click.stop.prevent="changeRoute(child.route)"
                        :class="{
                          'bg-white/20  ': $route.name === child.route,
                        }"
                      >
                        <div class="flex items-center">
                          <div v-if="child.icon" style="width: 20px">
                            <component :size="20" :is="child.icon" />
                          </div>
                          <div v-else-if="child.iconClass" style="width: 20px">
                            <div class="flex items-center justify-center">
                              <el-icon :class="child.iconClass" size="20" />
                            </div>
                          </div>
                          <div class="pl-5">
                            {{ child.name }}
                          </div>
                        </div>
                      </div>
                    </div>
                  </transition>
                </div>
              </div>
            </div>
          </Transition>
        </div>
      </nav>
      <div class="flex flex-row items-center justify-between"></div>
      <div class="mb-4 flex w-full items-center border-b border-gray-700 bg-[#272c2f] py-2 sm:hidden"></div>
    </div>
  </div>
  <transition name="slide-down">
    <div class="sticky top-0 z-40 flex w-full justify-between border-b border-gray-700 bg-arco-dark-card">
      <div v-if="currentTabs.length" class="flex overflow-x-auto overflow-y-hidden whitespace-nowrap font-title text-sm">
        <router-link
          v-for="tab in currentTabs"
          :key="tab.label"
          :to="tab.to"
          :id="tab.label"
          class="router-link-item -px-1 group relative -mb-px inline-flex h-10 items-center whitespace-nowrap p-2 py-6 text-center transition-transform duration-300 ease-in-out [text-shadow:1px_1px_2px_var(--tw-shadow-color)] focus:outline-none sm:px-4"
          :class="{ 'font-semibold text-blue-500': isActiveTab(tab), 'text-white': !isActiveTab(tab) }"
        >
          <div
            class="pointer-events-none absolute left-0 top-10 -z-10 h-full w-full transition-opacity duration-300 ease-in-out"
            :class="{ 'opacity-100': isActiveTab(tab), 'opacity-0 group-hover:opacity-100': !isActiveTab(tab) }"
            aria-hidden="true"
          >
            <div class="translate-z-0 absolute inset-0 m-auto h-[20px] w-[90%] max-w-[200px] rounded-full bg-blue-500 blur-[20px]"></div>
          </div>
          <el-icon :class="[tab.icon, 'mr-2', 'transition-transform duration-300 ease-in-out', 'group-hover:-translate-y-1', isActiveTab(tab) ? 'text-blue-500' : 'text-white']" size="20" />
          <p class="transition-all duration-300 ease-in-out group-hover:-translate-y-1">{{ tab.label }}</p>
        </router-link>
      </div>
      <div v-if="lockedTabs?.length" class="flex grow overflow-x-auto overflow-y-hidden whitespace-nowrap font-title text-sm">
        <router-link
          v-for="tab in lockedTabs"
          :key="tab.label"
          :to="tab.to"
          :id="tab.label"
          class="router-link-item -px-1 group relative -mb-px inline-flex h-10 items-center whitespace-nowrap p-2 py-6 text-center transition-transform duration-300 ease-in-out [text-shadow:1px_1px_2px_var(--tw-shadow-color)] focus:outline-none sm:px-4"
          :class="{ 'font-semibold text-blue-500': isActiveTab(tab), 'text-gray-300': !isActiveTab(tab) }"
        >
          <div
            class="pointer-events-none absolute left-0 top-10 -z-10 h-full w-full transition-opacity duration-300 ease-in-out"
            :class="{ 'opacity-100': isActiveTab(tab), 'opacity-0 group-hover:opacity-100': !isActiveTab(tab) }"
            aria-hidden="true"
          >
            <div class="translate-z-0 absolute inset-0 m-auto h-[20px] w-[90%] max-w-[200px] rounded-full bg-blue-500 blur-[20px]"></div>
          </div>
          <el-icon :class="[tab.icon, 'mr-2', 'transition-transform duration-300 ease-in-out', 'group-hover:-translate-y-1', isActiveTab(tab) ? 'text-blue-500' : 'text-gray-300']" size="20" />
          <p class="flex items-center transition-all duration-300 ease-in-out group-hover:-translate-y-1">{{ tab.label }} <el-icon class="ai ai-Lock ml-2" size="15" /></p>
        </router-link>
      </div>
      <div class="flex grow items-center justify-end">
        <div class="" v-for="item in menuTools" :key="item.key">
          <component class="mr-2" :is="item.component" v-bind="item.props" />
        </div>
      </div>
    </div>
  </transition>
  <transition name="slide-down">
    <div v-if="submenuTools.length" class="flex flex-row border-b border-gray-700 bg-arco-dark-card-2/80 text-sm">
      <div class="mx-2 flex w-full flex-row items-center justify-between">
        <div class="flex flex-row items-center justify-center" v-for="item in leftJustifiedTools" :key="item.key">
          <component class="my-2 mr-2" :is="item.component" v-bind="item.props" />
        </div>
        <div class="ml-auto flex flex-row items-center justify-center" v-for="item in rightJustifiedTools" :key="item.key">
          <component class="my-2 mr-2" :is="item.component" v-bind="item.props" />
        </div>
      </div>
    </div>
  </transition>
  <transition name="slide-down">
    <div v-if="activeTabChildren.length" class="flex h-12 flex-row border-b border-gray-700 bg-arco-dark-card-2/80 text-sm">
      <router-link class="m-2 flex items-center p-2" :class="{ 'm-2 rounded-md bg-arco-dark-card p-2': isActiveTab(child) }" v-for="child in activeTabChildren" :key="child.path" :to="child.to">
        {{ child.display_name }}
      </router-link>
      <div class="flex grow items-center justify-end">
        <div class="flex flex-row items-center justify-center" v-for="item in submenuTools" :key="item.key">
          <component class="mr-2" :is="item.component" v-bind="item.props" />
        </div>
      </div>
    </div>
  </transition>
  <div class=" flex flex-row bg-gradient-to-r from-sky-400/25 to-transparent px-4 py-2" v-if="isImpersonating">
    <div @click="exitImpersonation()" class="mr-3 flex cursor-pointer items-center">
      <el-icon :size="20" class="ai ai-Multiply-Circle rounded-full bg-blue-500/50" />
    </div>
    <div class="flex flex-col">
      <div class="flex">
        <span><b class="font-title">Currently impersonating:</b> {{ user.first_name }} {{ user.last_name }}</span>
      </div>
      <span class="text-xs text-blue-300"
        ><b>Role:</b> <span v-if="user?.default_role">{{ user.default_role }}</span> <b>Tenant:</b> <span v-if="user?.default_brand?.VUE_APP_TITLE">{{ user.default_brand.VUE_APP_TITLE }}</span></span
      >
    </div>
  </div>
  <div class=" h-0.5 bg-gradient-to-r from-sky-400 to-transparent" v-if="isImpersonating"></div>
  <div v-if="readOnly && isComplianceRoute" class="flex flex-row bg-gradient-to-r from-green-400/25 to-transparent px-4 py-2">
    <div class="mr-3 flex items-center">
      <el-icon :size="20" class="ai ai-Lock" />
    </div>
    <div class="flex flex-col">
      <div class="flex">
        <span><b class="font-title text-sm">Viewing read only assessment</b></span>
      </div>
    </div>
  </div>
  <div class="header"></div>
</template>

<script setup>
import { ref, computed, onMounted, inject, watch } from "vue";
import { useStore } from "vuex";
import NavHeaderMenu from "@/components/Nav/NavHeaderMenu.vue";
import NavNotifications from "@/components/Nav/NavNotifications.vue";
import { useRouter, useRoute } from "vue-router";
const store = useStore();
const router = useRouter();
const route = useRoute();
const showNotifications = ref(false);
const windowWidth = ref(0);
const drawer = ref(false);
const openAccountList = ref(false);
const isMenuVisible = ref(false);
const menuTools = inject("menuTools");
const submenuTools = inject("submenuTools");
const leftJustifiedTools = computed(() => {
  return submenuTools.value.filter((item) => item.justify === "left");
});

const rightJustifiedTools = computed(() => {
  return submenuTools.value.filter((item) => item.justify === "right");
});
const currentAccount = computed(() => store.getters["account/currentAccount"]);
const currentAccountDetail = computed(() => store.getters["account/currentAccountDetail"]);
const user = computed(() => store.getters["user/user"]);
const readOnly = computed(() => store.getters["user/userRole"] === "mssp_ro");
const userId = computed(() => store.getters["user/userId"]);
const activeMenu = computed(() => store.getters["user/activeMenu"]);
const subscription = computed(() => store.getters["tenant/subscription"]);
const userTenantDetail = computed(() => store.getters["tenant/userTenantDetail"]);
const features = computed(() => store.getters["tenant/features"]);
const kburl = computed(() => store.getters["ui/kb_url"]);
const VUE_APP_LOGO = computed(() => store.getters["ui/VUE_APP_LOGO"]);
const frameworks = computed(() => store.getters["efficiency/frameworks"]);
const assessors = computed(() => store.getters["metadata/assessors"]);
const locked = ["Heatmap", "Control Risks"];
const industries = computed(() => store.getters["metadata/industries"]);
import Tracker from "@openreplay/tracker";
import trackerAssist from "@openreplay/tracker-assist";
const tracker = new Tracker({
  projectKey: "Gr4I9O5xZPWtD8aaZSg1",
  ingestPoint: "https://replay.arcocyber.com/ingest",
  capturePerformance: true,
});
tracker.use(trackerAssist());
tracker.start();

const isComplianceRoute = computed(() => route.name === "ComplianceHome");
const isActiveTab = (tab) => {
  const normalizePath = (path) => (path.endsWith("/") ? path.slice(0, -1) : path);
  const normalizedCurrentPath = normalizePath(route.path);
  const normalizedTabTo = normalizePath(tab.to);
  if (normalizedCurrentPath === normalizedTabTo) {
    return true;
  }
  if (tab.children && tab.children.length > 0) {
    const isChildRouteActive = tab.children.some((child) => {
      const fullChildPath = normalizePath(`${normalizedTabTo}/${child.path}`);
      return normalizedCurrentPath.startsWith(`${fullChildPath}/`) || normalizedCurrentPath === fullChildPath;
    });

    return isChildRouteActive;
  }

  return false;
};
async function exitImpersonation() {
  return router.push({ name: "AuthLoading", query: { loginType: "impersonation_exit" } });
}

const openKb = function (url) {
  if (url) {
    window.open(url, "_blank").focus();
  }
};

watch(user, (newUser, oldUser) => {
  if (newUser && newUser.username) {
    tracker.start({
      userID: newUser.username,
      metadata: {
        tenant_id: newUser.tenant_id,
        default_role: newUser.default_role,
        user_id: newUser.id,
        isImpersonating: isImpersonating.value,
      },
    });
    tracker.setUserID(newUser.username);
  }
});
const currentTabs = computed(() => {
  const currentPathSegment = route.path.split("/")[1];
  const currentModule = subscription.value?.modules?.find((module) => module.route === currentPathSegment);

  if (!currentModule) return [];

  return currentModule.features
    .filter((feature) => !feature.locked)
    .map((feature) => ({
      label: feature.display_name,
      icon: feature.icon,
      meta: feature.meta,
      to: `/${currentModule.route}${feature.path ? `/${feature.path}` : ""}`,
      children: feature.children || [],
    }));
});

const lockedTabs = computed(() => {
  const currentPathSegment = route.path.split("/")[1];
  const currentModule = subscription.value?.modules?.find((module) => module.route === currentPathSegment);

  if (!currentModule) return [];

  return currentModule.features
    .filter((feature) => feature.locked && !locked.includes(feature.display_name))
    .map((feature) => ({
      label: feature.display_name,
      icon: feature.icon,
      meta: feature.meta,
      to: `/${currentModule.route}${feature.path ? `/${feature.path}` : ""}`,
      children: feature.children || [],
    }));
});

const currentModuleInfo = computed(() => {
  const currentPathSegment = route.path.split("/")[1];
  return subscription.value?.modules?.find((module) => module.name === currentPathSegment) || {};
});

const activeTabChildren = computed(() => {
  const findParentTab = (currentTab) => {
    if (currentTab && currentTab.to === route.path) {
      return currentTab;
    }
    return currentTabs.value.find((tab) => tab.children && tab.children.some((child) => route.path.startsWith(`${tab.to}/${child.path}`)));
  };

  const parentTab = findParentTab(currentTabs.value.find((tab) => isActiveTab(tab)));

  if (parentTab && parentTab.children) {
    return parentTab.children.map((child) => ({
      ...child,
      to: `${parentTab.to}/${child.path}`,
    }));
  }
  return [];
});
const segments = computed(() => {
  const pathSegments = route.path.split("/").filter((segment) => segment);

  return pathSegments.map((segment, index) => {
    const joinedPath = `/${pathSegments.slice(0, index + 1).join("/")}`;
    let foundTabOrFeature = null;
    currentTabs.value.some((tab) => {
      if (tab.to === joinedPath) {
        foundTabOrFeature = tab;
        return true;
      }
      const foundChild = tab.children?.find((child) => `${tab.to}/${child.path}` === joinedPath);
      if (foundChild) {
        foundTabOrFeature = {
          ...foundChild,
          label: foundChild.label || tab.label,
          meta: foundChild.meta || tab.meta,
          displayName: foundChild.display_name || tab.display_name,
        };
        return true;
      }
      return false;
    });
    if (!foundTabOrFeature && index === pathSegments.length - 1) {
      foundTabOrFeature = {
        label: currentModuleInfo.value.display_name,
        meta: currentModuleInfo.value.meta,
        displayName: currentModuleInfo.value.display_name,
      };
    }
    return {
      label: foundTabOrFeature?.label || segment.charAt(0).toUpperCase() + segment.slice(1),
      path: joinedPath,
      meta: foundTabOrFeature?.meta,
      displayName: foundTabOrFeature?.displayName || segment.charAt(0).toUpperCase() + segment.slice(1),
    };
  });
});

const currentSegmentLabel = computed(() => {
  const lastSegment = segments.value.at(-1);
  return {
    meta: lastSegment?.meta,
    displayName: lastSegment?.displayName,
  };
});
function toggleNotifications() {
  showNotifications.value = !showNotifications.value;
}

function handleResize() {
  windowWidth.value = window.innerWidth;
}

function toggleMenu() {
  isMenuVisible.value = !isMenuVisible.value;
}
const isImpersonating = computed(() => store.getters["user/isImpersonating"]);
function changeRoute(name, locked) {
  if (locked) {
    return showDialog();
  }
  isMenuVisible.value = false;
  router.push({ name });
}

onMounted(() => {
  handleResize();
  window.addEventListener("resize", handleResize);

  if (route.query.tenant) {
    store.dispatch("tenant/getActiveTenantBrand", route.query.tenant);
  } else if (localStorage.getItem("tenant_id") && localStorage.getItem("tenant_id") !== "undefined") {
    store.dispatch("tenant/getActiveTenantBrand", localStorage.getItem("tenant_id"));
  }

  if (currentAccount.value) {
    store.dispatch("insights/getNoOrSome", currentAccount.value);
  }

  if (!userId.value) {
    store.dispatch("user/getCurrentUser").then(() => {
      store.dispatch("account/getRecentAccounts");
    });
  } else {
    store.dispatch("account/getRecentAccounts");
  }

  if (!frameworks.value) {
    store.dispatch("efficiency/getAllFrameworks");
  }

  if (!assessors.value) {
    store.dispatch("metadata/getMetadataAssessors");
  }

  if (!industries.value) {
    store.dispatch("metadata/getMetadataIndustries");
  }
});
</script>
<style>
.slide-down-enter-from,
.slide-down-leave-to {
  transform: translateY(-100%);
  opacity: 0;
}

.slide-down-enter-to,
.slide-down-leave-from {
  transform: translateY(0);
  opacity: 1;
}
.slide-down-enter-active,
.slide-down-leave-active {
  transition: transform 0.2s ease-out, opacity 0.2s ease-out;
}
.slide-enter-active,
.slide-leave-active {
  transition: max-height 0.3s ease-in-out, opacity 0.3s ease-in-out;
}

.slide-enter-from,
.slide-leave-to {
  max-height: 0;
  opacity: 0;
}

.slide-enter-to,
.slide-leave-from {
  max-height: 500px;
  opacity: 1;
}

.fade-enter-from,
.fade-leave-to {
  opacity: 0;
  transform: translateY(-20px);
}

.fade-enter-to,
.fade-leave-from {
  opacity: 1;
  transform: translateY(0px);
}
.hierarchy-btn {
  transition: right 1s cubic-bezier(0.175, 0.885, 0.32, 1.275);
  right: -5px;
  z-index: 20;
}
.hierarchy-btn:hover {
  right: 0px;
  border-left: 1px solid #625d67;
  border-top: 1px solid #625d67;
  border-bottom: 1px solid #625d67;
}

.slide-fade-enter-active {
  transition: all 0.3 ease-out;
}

.slide-fade-leave-active {
  transition: all 0.8s cubic-bezier(1, 0.5, 0.8, 1);
}

.slide-fade-enter-from,
.slide-fade-leave-to {
  transform: translateX(20px);
  opacity: 0;
}

.group:hover .tab-underline::after {
  transform: scaleX(1);
  transform-origin: right;
}
.header::after {
  content: "";
  position: absolute;
  left: 0;
  right: 0;
  margin-right: 20px;
  top: 20;
  height: 20px;
  background: linear-gradient(to top, rgba(0, 0, 0, 0), #0e1315);
  pointer-events: none;
}
</style>
